import React from 'react';
import MetaTags from '../components/Seo/MetaTags';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import useSiteMetadata from '../hooks/useSiteMetadata';
import IntercomButton from '../components/Intercom/IntercomButton';
import ChatIcon from '../assets/icons/font-awesome/solid/comment-alt-lines.svg';
import EmailIcon from '../assets/icons/font-awesome/solid/envelope.svg';
import MarkerIcon from '../assets/icons/font-awesome/solid/map-marker-alt.svg';

const ContactsPage = () => {
  const { t } = useTranslation('Contacts');
  const { organization } = useSiteMetadata();
  const { address, email, telegramChatLink } = organization;
  const { street, city, postcode, country } = address;

  return (
    <>
      <MetaTags title={t('seo.title')} description={t('seo.description')} />

      <div className="inner-banner pt-29 pb-md-11 bg-default-2">
        <Container>
          <Row className="justify-content-center pt-5">
            <Col lg="9" xl="8">
              <div className="px-md-15 text-center">
                <h1 className="title gr-text-2 mb-9">{t('title')}</h1>
                <p className="gr-text-8 mb-13">{t('subtitle')}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="pb-5 pb-md-33 bg-default-2 ">
        <Container>
          <Row className="justify-content-center">
            <Col xs="12" className="mb-9">
              <Row>
                <Col md="5" lg="4" className="mb-13">
                  <div className="single-contact-widget d-flex">
                    <div className="widget-icon circle-sm-2 bg-white gr-text-6 text-primary mr-7">
                      <span className="fa-svg-icon svg-color-primary">
                        <ChatIcon />
                      </span>
                    </div>

                    <div>
                      <h3 className="gr-text-6 mb-5">{t('channel1.title')}</h3>

                      <p
                        className="gr-text-7 mb-0"
                        style={{ marginLeft: '-12px' }}
                      >
                        <IntercomButton
                          text={t('channel1.intercom')}
                          cls="text-left"
                          isLink
                        />

                        <a
                          className="btn btn-link py-2 px-5 text-left"
                          style={{ justifyContent: 'left' }}
                          href={telegramChatLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t('channel1.telegram')}
                        </a>
                      </p>
                    </div>
                  </div>
                </Col>

                <Col md="5" lg="4" className="mb-13">
                  <div className="single-contact-widget d-flex">
                    <div className="widget-icon circle-sm-2 bg-white gr-text-6 text-primary mr-7">
                      <span className="fa-svg-icon svg-color-primary">
                        <EmailIcon />
                      </span>
                    </div>

                    <div>
                      <h3 className="gr-text-6 mb-5">{t('channel2.title')}</h3>

                      <p
                        className="gr-text-7 mb-0"
                        style={{ marginLeft: '-12px' }}
                      >
                        <a
                          className="btn btn-link py-2 px-5"
                          style={{ justifyContent: 'left' }}
                          href={`mailto:${email}`}
                        >
                          {email}
                        </a>
                      </p>
                    </div>
                  </div>
                </Col>
                <Col md="5" lg="4" className="mb-13">
                  <div className="single-contact-widget d-flex">
                    <div className="widget-icon circle-sm-2 bg-white gr-text-6 text-primary mr-7">
                      <span className="fa-svg-icon svg-color-primary">
                        <MarkerIcon />
                      </span>
                    </div>

                    <div>
                      <h3 className="gr-text-6 mb-7">{t('channel3.title')}</h3>

                      <p className="gr-text-9 mb-0">Web42 Solutions Ltd</p>
                      <p className="gr-text-9 mb-0">{street}</p>
                      <p className="gr-text-9 mb-0">{city}</p>
                      <p className="gr-text-9 mb-0">{postcode}</p>
                      <p className="gr-text-9 mb-0">{country}</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ContactsPage;
