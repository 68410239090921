import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function IntercomButton(props) {
  const { text, isLink, cls } = props;
  const className = classNames(
    'btn py-2 px-5',
    {
      'btn-primary': !isLink,
      'btn-link': isLink,
    },
    cls
  );

  return (
    <button
      className={className}
      onClick={() => {
        if (window.Intercom) {
          // eslint-disable-next-line no-undef
          Intercom('show');
        }
      }}
    >
      {text}
    </button>
  );
}

IntercomButton.propTypes = {
  text: PropTypes.string.isRequired,
  isLink: PropTypes.bool,
  cls: PropTypes.string,
};

export default IntercomButton;
